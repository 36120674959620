import React, { useMemo } from 'react';

import Layout from 'src/components/layout/layout';
import MembershipUsers from 'src/components/membership-users/membership-users';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const MemberPage = ({ location }) => {
  usePrivateRoute();
  return (
    <Layout>
      <SEO title="Member" />
      <MembershipUsers />
    </Layout>
  );
};
export default MemberPage;

import React, { useMemo, useState } from 'react';

import { Checkbox, Grid, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { DataCols } from '@clubspark-react/clubspark-react-tools';

import AdminTable from '../admin-table/admin-table';
import Button from '../button/button';
import { User } from '../member/member';
import * as styles from './membership-users.module.less';

type UsersProps = {
  users: User[];
};

const users: User[] = [
  {
    id: 1,
    firstName: 'Mailing',
    lastName: '123 My State Drive',
    role: 'Lincoln',
    email: 'Nebraska',
    organization: '68592',
    status: '68592',
    lastLoggedIn: '68592',
    checkbox: true,
  },
  {
    id: 2,
    firstName: 'Mailing',
    lastName: '123 My State Drive',
    role: 'Lincoln',
    email: 'Nebraska',
    organization: '68592',
    status: '68592',
    lastLoggedIn: '68592',
    checkbox: true,
  },
  {
    id: 3,
    firstName: 'Mailing',
    lastName: '123 My State Drive',
    role: 'Lincoln',
    email: 'Nebraska',
    organization: '68592',
    status: '68592',
    lastLoggedIn: '68592',
    checkbox: true,
  },
  {
    id: 4,
    firstName: 'Mailing',
    lastName: '123 My State Drive',
    role: 'Lincoln',
    email: 'Nebraska',
    organization: '68592',
    status: '68592',
    lastLoggedIn: '68592',
    checkbox: true,
  },
  {
    id: 5,
    firstName: 'Mailing',
    lastName: '123 My State Drive',
    role: 'Lincoln',
    email: 'Nebraska',
    organization: '68592',
    status: '68592',
    lastLoggedIn: '68592',
    checkbox: true,
  },
  {
    id: 6,
    firstName: 'Mailing',
    lastName: '123 My State Drive',
    role: 'Lincoln',
    email: 'Nebraska',
    organization: '68592',
    status: '68592',
    lastLoggedIn: '68592',
    checkbox: true,
  },
  {
    id: 7,
    firstName: 'Mailing',
    lastName: '123 My State Drive',
    role: 'Lincoln',
    email: 'Nebraska',
    organization: '68592',
    status: '68592',
    lastLoggedIn: '68592',
    checkbox: true,
  },
  {
    id: 8,
    firstName: 'Mailing',
    lastName: '123 My State Drive',
    role: 'Cincoln',
    email: 'Nebraska',
    organization: '68592',
    status: '68592',
    lastLoggedIn: '68592',
    checkbox: true,
  },
  {
    id: 9,
    firstName: 'Mailing',
    lastName: '123 My State Drive',
    role: 'Bincoln',
    email: 'Nebraska',
    organization: '68592',
    status: '68592',
    lastLoggedIn: '68592',
    checkbox: true,
  },
  {
    id: 10,
    firstName: 'Aailing',
    lastName: 'Ay State Drive',
    role: 'Aincoln',
    email: 'Nebraska',
    organization: '68592',
    status: '68592',
    lastLoggedIn: '68592',
    checkbox: true,
  },
  {
    id: 11,
    firstName: 'Bailing',
    lastName: 'My State Drive',
    role: 'Lincoln',
    email: 'Nebraska',
    organization: '68592',
    status: '68592',
    lastLoggedIn: '68592',
    checkbox: true,
  },
];

const UsersTable: React.FC<UsersProps> = ({ users }: UsersProps) => {
  const [checked, setChecked] = useState(false);

  const [selected, setSelected] = useState<string[]>([]);

  const handleChange = () => {
    setChecked(!checked);
  };

  const setSelectedRows = (row) => {
    setSelected(row);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleClick = (user: User, event: React.MouseEvent<unknown>) => {
    const name = user.id;
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
    setChecked(!checked);
  };

  const { t: tl } = useTranslation();
  const cols: DataCols<User> = useMemo(
    () => [
      {
        key: 'checkbox',
        title: '',
        getValue: (p) => {
          return (
            <Checkbox
              classes={{
                root: styles.checkboxRoot,
                colorSecondary: styles.checkboxColorSecondary,
              }}
              checked={isSelected && isSelected(p.id)}
            />
          );
        },
      },
      {
        key: 'firstName',
        title: tl('first name'),
        getValue: (p) => p.firstName,
      },
      { key: 'lastName', title: tl('last name'), getValue: (c) => c.lastName },
      { key: 'role', title: tl('role'), getValue: (c) => c.role },
      { key: 'email', title: tl('email'), getValue: (c) => c.email },
      {
        key: 'organization',
        title: tl('organization'),
        getValue: (c) => c.organization,
      },
      { key: 'status', title: tl('status'), getValue: (c) => c.status },
      {
        key: 'lastLoggedIn',
        title: tl('last logged in'),
        getValue: (c) => c.lastLoggedIn,
      },
    ],
    [tl, checked, selected],
  );
  return (
    <AdminTable
      columns={cols}
      data={users}
      hasSelectAllRows
      onRowClick={handleClick}
      selectedRows={selected}
      setSelectedRows={setSelectedRows}
    />
  );
};

const MembershipUsers = () => {
  const { t: tl } = useTranslation();
  return (
    <Grid container>
      <Paper className={styles.paper}>
        <Grid container>
          <Typography className={styles.tableHeading}>{tl('users')}</Typography>
          <Button spacing={{ margins: { auto: 'left' } }}>+&nbsp;{tl('add user')}</Button>
        </Grid>

        {/* <Button onClick={handleAddClick} classname={styles.addPaymentButton}>
                  +&nbsp;{t('add user')}
                </Button> */}
        {/* <CustomDialog
                  open={openAdd}
                  onClose={handleAddClose}
                  title={t('add contact')}
                  content={<AddAdressDialog />}
                  actions={
                    <>
                      <Button>{t('add')}</Button>
                      <Button onClick={handleAddClose}>{t('close')}</Button>
                    </>
                  }
                /> */}

        <UsersTable users={users} />
      </Paper>
    </Grid>
  );
};

export default MembershipUsers;
